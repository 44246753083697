import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userProfileState, userState } from "../state";

const TopBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const setUser = useSetRecoilState(userState);
  const userProfile = useRecoilValue(userProfileState);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.clear();
    setUser(null);
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Link
          component={RouterLink}
          to="/"
          sx={{ flexGrow: 1, color: "white" }}
          underline="none"
        >
          <Typography variant="h6" component="div">
            Dashboard
          </Typography>
        </Link>
        <Box>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar src={userProfile.ImageUrl} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                bgcolor: "primary.main",
                color: "white",
              }}
            >
              <Avatar
                sx={{
                  width: (theme) => theme.spacing(7),
                  height: (theme) => theme.spacing(7),
                }}
                src={userProfile.ImageUrl}
              />
              <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                <Box>
                  {userProfile.given_name + " " + userProfile.family_name}
                </Box>
                <Box>{userProfile.email}</Box>
              </Box>
            </Box>
            <MenuItem
              component={RouterLink}
              to="/profile"
              onClick={() => setAnchorEl(null)}
            >
              <AccountCircle sx={{ marginRight: 1 }} />
              My Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ExitToApp sx={{ marginRight: 1 }} />
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
