import Axios from "axios";

const url =
  "https://2r5zj04wob.execute-api.us-east-1.amazonaws.com/Stage/UserProfile/";

export const updateProfile = (username, values) => {
  return Axios.put(url + username, values);
};

export const getProfile = (username) => {
  return Axios.get(url + username);
};
