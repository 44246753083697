import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { Outlet } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import TopBar from "../components/TopBar";
import { getProfile } from "../services/profileService";
import { userProfileState, userState } from "../state";
import Profile from "../views/app/Profile";

const MainLayout = () => {
  const user = useRecoilValue(userState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const { data } = await getProfile(user.sub);
      setUserProfile(data);
    };
    fetchUserProfile();
  }, [setUserProfile, user]);

  if (!userProfile) {
    return <CircularProgress />;
  }

  return (
    <>
      <TopBar />
      {!userProfile.given_name ? <Profile /> : <Outlet />}
    </>
  );
};

export default MainLayout;
