import { atom } from "recoil";
import { getUser } from "../services/authService";

export const memesState = atom({
  key: "memesState",
  default: [],
});

export const userState = atom({
  key: "user",
  default: getUser(),
});

export const userProfileState = atom({
  key: "userProfile",
  default: null,
});
